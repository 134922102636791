import { defineStore } from 'pinia'

export type ApiError = {
  data?: any
  message: string
  errors?: {
    [key: string]: string[]
  }
}

export const fetchToApiError = (error: any): ApiError => {
  if (error?.data?.data?.errors || error?.data?.data?.message) {
    return error.data.data
  }

  if (error?.data?.errors || error?.data?.message) {
    return error.data
  }

  if (error?.errors || error?.message) {
    return error
  }

  return { message: `${error}` }
}

export const useApiErrorsStore = defineStore('api_errors', () => {
  const messages = ref<Record<string, string>>({})
  const errors = ref<Record<string, string[]>>({})

  const getMessage = computed(() => {
    return (key: string) => {
      return messages.value[key] || ''
    }
  })

  const getError = computed(() => {
    return (keyFilter?: string) => {
      if (!keyFilter) {
        return []
      }
      const errorsWithKeyFilter = []
      for (const [key, value] of Object.entries(errors.value)) {
        if (key.includes(keyFilter)) {
          errorsWithKeyFilter.push(...value)
        }
      }
      return errorsWithKeyFilter
    }
  })

  const getApiError = computed(() => {
    return (apiKey?: string, except: string[] = [], withMessage = true) => {
      if (!apiKey) {
        return []
      }

      const err = []
      withMessage && messages.value[apiKey] && err.push(messages.value[apiKey])

      for (const [key, value] of Object.entries(errors.value)) {
        if (key.startsWith(`${apiKey}-`) && !except.includes(key)) {
          err.push(...value)
        }
      }
      return err
    }
  })

  function addApiError(apiError: ApiError, apiKey = 'error') {
    messages.value[apiKey] = apiError.message

    for (const [key, value] of Object.entries(apiError.errors || [])) {
      errors.value[`${apiKey}-${key}`] = value
    }
  }

  function deleteApiError(apiKey: string) {
    delete messages.value[apiKey]

    for (const key in errors.value) {
      if (key.startsWith(`${apiKey}-`)) {
        delete errors.value[key]
      }
    }
  }

  function addError(error: string[], key = 'error') {
    errors.value[key] = error
  }

  function deleteError(key: string) {
    delete errors.value[key]
  }

  return {
    getMessage,
    getError,
    getApiError,
    addApiError,
    deleteApiError,
    addError,
    deleteError
  }
})
