<template>
  <AppProvider>
    <NuxtPage />
  </AppProvider>
</template>

<script setup lang="ts">
import type { StoreWithDomain } from './server/routes/nuxt-api/domains.get'

const { data } = await useFetch<StoreWithDomain[]>('/nuxt-api/domains')

useState('valid_domains', () => data.value?.map((el) => el.domain) || [])
</script>

<style>
.app-enter-active,
.app-leave-active {
  transition: all 0.2s;
}
.app-enter-from,
.app-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
