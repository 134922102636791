<template>
  <div>
    <Html :lang="head.htmlAttrs?.lang" :dir="localeProperties.dir" />

    <Head>
      <Title>{{ t('mevcut') }}</Title>

      <Meta
        name="description"
        hid="description"
        :content="t('mevcut_description')"
      />

      <template v-for="link in head.link" :key="link.hid">
        <Link
          :id="link.hid"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang"
        />
      </template>

      <template v-for="meta in head.meta" :key="meta.hid">
        <Meta
          :id="meta.hid"
          :property="meta.property"
          :content="meta.content"
        />
      </template>
    </Head>
  </div>
</template>

<script setup lang="ts">
const head = useLocaleHead({ addSeoAttributes: true })
const { t, localeProperties } = useI18n()
</script>
