import getAllRoutes from './AppApiRoutes'
import type { City } from '~/composables/useMenuModel'
import { PatternStoreType } from '~~/composables/useMenuModel'
export const useSettings = () => {
  const settings = useState<GlobalSettings>('settings', () => ({}))

  const setSettings = (data: GlobalSettings) => (settings.value = data)

  return {
    settings,
    setSettings
  }
}

export const useGroupSettings = async (group: number, key: string) => {
  const { data } = await useBasicFetch<ApiResponse<any>>(
    getAllRoutes().settingsRoutes.getSettingsWithGroupByServer,
    { query: { group, domain: useDomainHeader() } }
  )
  if (data.value) {
    return data.value[key] as any
  }
}
export const useStoresSettings = () => {
  const settingsStore = useState<StoresSetting[]>('StoresSetting', () => [])
  function getSettingsByKey(key: string) {
    const sittingTemp = settingsStore.value.find((el) => el.key === key)
    return sittingTemp?.pivot?.value.trim()
  }
  async function fetchSettingsStore(store: Store) {
    const { data } = await useBasicFetch<StoresSetting[]>(
      getAllRoutes().settingsRoutes.getSettingsStoreByServer,
      {
        query: {
          storeId: store?.id,
          domain: useDomainHeader(),
          group: 17
        }
      }
    )
    if (data.value?.length) {
      data.value?.forEach((el) => {
        if (el?.pivot?.value.trim() && !settingsStore.value?.includes(el)) {
          settingsStore.value.push(el)
        }
      })
    }
  }
  return {
    settingsStore,
    fetchSettingsStore,
    getSettingsByKey
  }
}

export const useStoreTypes = (pattern?: PatternStoreType) => {
  const storeTypes = useState<StoreType[]>('storeTypes', () => [])
  const storeTypesParents = computed(() => {
    if (pattern) {
      return storeTypes.value?.filter(
        (el) => el.parent_id == null && el.patternStore === pattern
      )
    }
    return storeTypes.value?.filter((el) => el.parent_id == null)
  })

  const setStoreTypes = (data: StoreType[]) => (storeTypes.value = data)
  return {
    storeTypes,
    setStoreTypes,
    storeTypesParents
  }
}
export const useOffersNumber = () => {
  const offersNumberLocal = useState<number>('offersNumber', () => 0)
  const offersNumber = computed(() => {
    return offersNumberLocal.value
  })

  const setNewOffersNumber = (data: number) => (offersNumberLocal.value = data)
  return {
    offersNumber,
    setNewOffersNumber
  }
}

export const useMaterialDefinitionsStore = () => {
  const materialDefinitions = useState<MaterialDefinition[]>(
    'MaterialDefinitionsStore',
    () => []
  )
  async function fetchMaterialDefinitionsStore(store: Store) {
    const { data } = await useBasicFetch<SuccessResponse<MaterialDefinition[]>>(
      getAllRoutes().materialsRoutes.getMterialDefinitions,
      {
        query: {
          type_id: store?.type_id,
          limit: 1000,
          domain: useDomainHeader()
        }
      }
    )

    if (data.value?.data?.length) {
      const { materialDfinitionsModel } = useMenuModel()
      materialDefinitions.value =
        materialDfinitionsModel(data.value?.data) ?? []
    }
  }
  return {
    materialDefinitions,
    fetchMaterialDefinitionsStore
  }
}
