import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import { fetchToApiError, useApiErrorsStore } from './api_errors'
import getAllRoutes from '~/composables/AppApiRoutes'

export const usePlacesStore = defineStore('places', {
  state: (): {
    cities: City[]
    places: AgentPlace[]
    city_id?: string
    place_id?: string
  } => ({
    cities: [],
    places: [],
    city_id: undefined,
    place_id: undefined
  }),
  getters: {
    cityId: (state) => {
      return state.city_id
    },

    getPlaces: (state) => state.places,
    selectedPlace: (state) =>
      state.places?.find(
        (el) => el.id.toString() === state.place_id?.toString()
      ),

    selectedCity: (state) =>
      state.cities?.find((el) => el.id.toString() === state.city_id?.toString())
  },

  actions: {
    setCityId(id?: string) {
      this.city_id = id
    },
    setPlaceId(id?: string) {
      this.place_id = id
    },
    clearCache() {
      this.setPlaceId(undefined)
      this.setCityId(undefined)
      this.places = []
    },

    setPlace(place: AgentPlace) {
      this.city_id = place.city_id?.toString()
      this.place_id = place.id.toString()
    },

    getCityByCityId(cityId?: number) {
      if (!cityId) {
        return undefined
      }
      return this.cities?.find((el) => el.id === cityId)
    },
    getPlaceById(placeId?: number | string | null) {
      return this.places?.find((el) => el.id == placeId)
    },

    addNewPlace(place: AgentPlace) {
      this.places?.push(place)
      this.setPlace(place)
    },
    deletePlaceById(placeId?: number) {
      this.places.splice(
        this.places.findIndex((el) => el.id === placeId),
        1
      )
    },
    disableSelectedCityForce() {
      const { status } = useAuth()

      return status.value === 'authenticated' ? this.place_id : this.city_id
    },
    async fetchAgentPlace() {
      const { status } = useAuth()
      if (status.value === 'authenticated') {
        const { data, error } = await useBasicFetch(
          getAllRoutes().placesRoutes.agentPlaceByServer,
          {
            transform: (data: SuccessResponse<AgentPlace[]>) => {
              const { placeModel } = useMenuModel()
              return data.data.map(placeModel)
            }
          }
        )
        if (!error.value) {
          this.places = data.value || []

          if (!this.place_id) {
            const { session } = useProfile()
            if (session.value?.last_agent_place_id) {
              const agentPlace = this.getPlaceById(
                session.value?.last_agent_place_id
              )
              if (agentPlace) {
                this.setPlace(agentPlace)
              }
            }
          }
        }
      }
    },
    async deleteAgentPlace(placeId: number) {
      const toast = useToast()
      const { t } = useNuxtApp().$i18n
      const { addApiError, deleteApiError } = useApiErrorsStore()

      const { status } = useAuth()

      if (status.value !== 'authenticated') {
        toast.error(t('login_required'), { timeout: 1000 })
        return false
      }

      try {
        deleteApiError(`deleteAgentPlace-${placeId}`)
        await useBasicFetch(getAllRoutes().placesRoutes.deleteAgentPlaceByServer, {
          method: 'DELETE',
          body: {
            place_id: placeId
          }
        })
        this.deletePlaceById(placeId)
        return true
      } catch (error) {
        toast.error(t('delete_failed'), { timeout: 1000 })
        addApiError(fetchToApiError(error), `deleteAgentPlace-${placeId}`)
        return false
      }
    },
    async fetchCities(cityModel:any) {
      const locale = useNuxtApp().$i18n?.locale?.value
      const { data: citiesTemp, error } = await useBasicFetch(
        getAllRoutes().citiesRoutes.getCities,
        {
          query: { app_locale: locale, offset: 0, limit: 100 },
          key: 'nuxt-api-laravel-settings-cities-' + locale,
          transform: (data) => {
            return data?.data?.map(cityModel) as City[]
          }
        }
      )

      if (!error.value) {
        this.cities = citiesTemp.value || []
      }
    },
    async updateAgentPlace(place: AgentPlace) {
      const toast = useToast()
      const { t } = useNuxtApp().$i18n
      const { clearCache } = useCacheSession()

      try {
        await useBasicFetch(
          getAllRoutes().placesRoutes.updateAgentPlaceByServer,
          {
            method: 'POST',
            body: {
              app_locale: useNuxtApp().$i18n?.locale?.value,
              agent_place_id: place.id
            }
          }
        )
        this.setPlace(place)
        clearCache()
      } catch (error) {
        toast.error(t('delete_updated'), { timeout: 1000 })
        return false
      }
    }
  },

  persist: [
    { pick: ['city_id', 'place_id'], storage: persistedState.cookies },
    {
      pick: ['places'],
      storage: persistedState.localStorage
    }
  ]
})
