import getAllRoutes from './AppApiRoutes'
import { CounterClickingTypes } from '~~/composables/useMenuModel'

export const useIncreaseCounterClicking = async (
  type: CounterClickingTypes,
  id: any
) => {
  if (!id) {
    return
  }
  return await useBasicFetch(
    getRouteCounterClicking(type)(id),
    { method: 'POST' }
  )
}

function getRouteCounterClicking(type?: CounterClickingTypes) {
  const counterClickingRoutes = getAllRoutes().counterClickingRoutes
  switch (type) {
    case CounterClickingTypes.announcement:
      return counterClickingRoutes.clickingInAnnouncement
    case CounterClickingTypes.storeAnnouncement:
      return counterClickingRoutes.clickingInStoreAnnouncement
    case CounterClickingTypes.customNotification:
      return counterClickingRoutes.clickingInCustomNotification

    default:
      return counterClickingRoutes.clickingInAnnouncement
  }
}
