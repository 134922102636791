<template>
  <div>
    <I18nProvider />

    <NuxtLayout :name="layout">
      <slot />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'

const { layout } = useDomainHost()

const locale = useNuxtApp().$i18n?.locale?.value

const allRoutes = getAllRoutes()

const { data } = await useBasicFetch<GlobalSettings>(
  allRoutes.settingsRoutes.getSettingsByServer,
  {
    query: {
      domain: useDomainHeader(),
      app_locale: locale
    },
    key: 'nuxt-api-laravel-settings-index-' + locale
  }
)

const { setSettings } = useSettings()
setSettings(data.value ?? {})
</script>
