<template>
  <div class="flex flex-col h-screen items-center justify-center">
    <v-icon
      icon="mdi mdi-alert-circle-outline"
      :size="48"
      class="text-red-500 mb-5"
    />
    <div class="text-xl font-bold text-red-500">{{ error.statusCode }}</div>
    <div class="text-lg mb-3">
      <div v-if="error.statusCode == 404">
        {{ t('error_404') }}
      </div>
      <div v-else-if="error.statusCode == 500">
        {{ t('error_500') }}
      </div>
      <div v-else>
        {{ translate ? t(error.message) : error.message }}
      </div>
    </div>

    <button class="my-10" @click="handleError">{{ t('back_home') }}</button>
    <div class="h-10 sm:h-12"></div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  error: {
    url: string
    message: string
    statusCode: number
    statusMessage?: string
  }
  translate?: boolean
}>()

const { t } = useI18n()

const handleError = () => clearError({ redirect: '/' })
</script>
